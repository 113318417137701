<!-- 节假日选择器 -->
<template>
  <div>
    <div class="backgroundMonth">
      {{ currentMonth }}月
    </div>
    <div class="tool-button">
      <a-button @click="setCurrentYearWeekendIsHoliday">设置 {{ currentYear }} 年周末为休息日</a-button>
      <a-button @click="setCurrentMonthWeekendIsHoliday">设置 {{ currentMonth }} 月周末为休息日</a-button>
    </div>
    <a-calendar @change="calendarChange" @panelChange="calendarChange">
      <!-- 期渲染插槽 -->
      <template slot="dateCellRender" slot-scope="value">
        <holiday-select-date-cell :date="value" :isHoliday="holidays.includes(value.format(format))"
          @switchHoliday="switchHoliday" />
      </template>
      <!-- 月份渲染插槽 -->
      <template slot="monthCellRender" slot-scope="value">
        <ul>
          <li v-for="item in getMonthHolidayCount(value)">
            {{ item }}
          </li>
        </ul>
      </template>
    </a-calendar>
  </div>
</template>
<script>
import { addHoliday, delHoliday, listHoliday } from '@/api/config/holiday'
import HolidaySelectDateCell from './HolidaySelectDateCell.vue';
import moment from 'moment'
export default {
  name: 'HolidaySelect',
  components: {
    HolidaySelectDateCell
  },
  data() {
    return {
      holidays: [],
      format: 'YYYY-MM-DD',
      // 当前月, 从1开始
      currentMonth: moment().month() + 1,
      // 当前年
      currentYear: moment().year(),
    }
  },
  created() {
    this.initHolidayData();
  },
  methods: {
    // 切换假日
    switchHoliday(date) {
      const formatString = date.format(this.format);
      if (this.holidays.includes(formatString)) {
        delHoliday({ date: [formatString] }).then(r => {
          if (r.success) {
            this.holidays.splice(this.holidays.indexOf(formatString), 1);
            this.$message.success('切换成功');
          } else {
            this.$message.error('操作失败');
          }

        });
      } else {
        addHoliday({ date: [formatString] }).then(r => {
          if (r.success) {
            this.holidays.push(formatString)
            this.$message.success('切换成功');
          } else {
            this.$message.error('操作失败');
          }
        });
      }
    },
    // 返回月休息日
    getMonthHolidayCount(date) {
      const formatString = date.format('YYYY-MM');
      return this.holidays
        .filter(item => item.startsWith(formatString))
        .map(item => `${item} 周${moment(item).format('dd')}`)
    },
    // 日期改变, 更改背景月份
    calendarChange(date) {
      this.currentMonth = date.month() + 1;
      this.currentYear = date.year();
    },
    // 设置本年周末为休息日
    setCurrentYearWeekendIsHoliday() {
      this.$confirm({
        title: `确认设置 ${this.currentYear} 年周末为休息日吗?`,
        content: `确认设置 ${this.currentYear} 年周末为休息日吗? (此操作不能撤销)`,
        onOk: () => {
          const date = moment(`${this.currentYear}-01-01`);
          const changeDatas = [];
          while (true) {
            if (date.year() != this.currentYear) {
              break;
            }
            const dateStr = date.format(this.format);
            // 星期
            const week = date.day();
            // 星期日为 0, 星期六为 6
            if (week == 0 || week == 6) {
              if (!this.holidays.includes(dateStr)) {
                changeDatas.push(dateStr);
              }
            }
            date.add(1, 'd');
          }
          if (!changeDatas.length) {
            return;
          }
          this.holidays.push(...changeDatas);
          addHoliday({ date: changeDatas }).then(r => {
            if (r.success) {
              this.$message.success(`设置成功, 共计设置 ${changeDatas.length} 天休息日, 分别是: ${changeDatas}`);
              this.holidays.push(formatString)
            } else {
              this.$message.error('操作失败');
            }
          });
        },
      });
    },
    // 设置本月周末为休息日
    setCurrentMonthWeekendIsHoliday() {
      this.$confirm({
        title: `确认设置 ${this.currentMonth} 月周末为休息日吗?`,
        content: `确认设置 ${this.currentMonth} 月周末为休息日吗? (此操作不能撤销)`,
        onOk: () => {
          const date = moment(`${this.currentYear}-${this.currentMonth}-01`);
          const changeDatas = [];
          while (true) {
            if (date.month() + 1 != this.currentMonth) {
              break;
            }
            const dateStr = date.format(this.format);
            // 星期
            const week = date.day();
            // 星期日为 0, 星期六为 6
            if (week == 0 || week == 6) {
              if (!this.holidays.includes(dateStr)) {

                changeDatas.push(dateStr);
              }
            }
            date.add(1, 'd');
          }
          if (!changeDatas.length) {
            return;
          }
          this.holidays.push(...changeDatas);
          addHoliday({ date: changeDatas }).then(r => {
            if (r.success) {
              this.$message.success(`设置成功, 共计设置 ${changeDatas.length} 天休息日, 分别是: ${changeDatas}`);
              this.holidays.push(formatString)
            } else {
              this.$message.error('操作失败');
            }
          });
        },
      });
    },
    // 初始化数据
    initHolidayData() {
      listHoliday().then(r => {
        this.holidays = r.data;
      })
    }
  },
}
</script>
<style>
.backgroundMonth {
  position: absolute;
  width: 100%;
  height: 80%;
  font-size: 200px;
  color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-button {
  position: absolute;
  margin-top: 11px;
}

.tool-button .ant-btn+.ant-btn {
  margin-left: 10px;
}
</style>